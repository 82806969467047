import SiteMessageBanner from "./SiteMessageBanner";

export default class PreventUserLoginBanner {
    constructor() {
        let copyToUse = window.OverDrive.SiteCopy.get("preventLoginBanner");
        let banner = new SiteMessageBanner(copyToUse, "PreventUserLoginMessage", 14 * 60 * 24, false);
        this.render(banner);
    }

    render(banner) {
        if ($(".SiteMessageBannerContainer.PreventLoginBanner").length > 0) {
            banner.render();
        }
    }
}