
export default class CookieSettingsModal {
    constructor(AllowPerformanceCookies, AllowUserBehaviorCookies, ShowMessage) {
        this.allowPerformanceCookies = AllowPerformanceCookies;
        this.allowUserBehaviorCookies = AllowUserBehaviorCookies;
        this.showMessage = ShowMessage;

        this.targetEle = $(".modal-backdrop");
        this.render();
    }

    buildTemplate() {
        return `
            <div class="CookieSettingsModal" id="cookie-modal">
                <header>
                    <h1 class="modal-header-text">${window.OverDrive.SiteCopy.get("cookieSettings.cookieSettingsHeader")}</h1>
                </header>
                
                <button class="close"> &times; </button>
                <div class="modal-body">
                    <p class="cookie-modal-description">${window.OverDrive.SiteCopy.get("cookieSettings.description")}</p>
                    <div class="c_half cookies">
                        <input type="checkbox" disabled="disabled" checked="checked">
                        ${window.OverDrive.SiteCopy.get("cookieSettings.requiredCookiesLabel")}
                        <p class="cookie-description">${window.OverDrive.SiteCopy.get("cookieSettings.requiredCookiesDescription")}</p>
                    </div>
                    <div class="c_half cookies">
                        <input name="performance-cookie" type="checkbox" ${this.allowPerformanceCookies ? `checked="checked"` : ``}>
                        ${window.OverDrive.SiteCopy.get("cookieSettings.performanceCookiesLabel")}
                        <p class="cookie-description">${window.OverDrive.SiteCopy.get("cookieSettings.performanceCookiesDescription")}</p>
                    </div>
                    <div class="c_half cookies">
                        <input name="behavior-cookie" type="checkbox" ${this.allowUserBehaviorCookies ? `checked="checked"` : ``}>
                        ${window.OverDrive.SiteCopy.get("cookieSettings.researchCookiesLabel")}
                        <p class="cookie-description">${window.OverDrive.SiteCopy.get("cookieSettings.researchCookiesDescription")}</p>
                    </div>
                    
                    <p class="cookie-policy">${window.OverDrive.SiteCopy.get("cookieSettings.learnMoreAboutCookies")}</p>
                    <p class="privacy-policy">${window.OverDrive.SiteCopy.get("cookieSettings.learnMoreAboutPrivacy")}</p>
                </div>

                <button class="save-button">${window.OverDrive.SiteCopy.get("cookieSettings.confirm")}</button>
            </div>
        `;
    }; 

    render() {
        this.targetEle.empty();
        this.targetEle.append(this.buildTemplate());
        this.targetEle.show();

        $(".CookieSettingsModal .close, .modal-backdrop").on("click", () => {
            this.close();
        });

        $(".CookieSettingsModal").on("click", (e) => {
            e.stopPropagation();
        }); 

        $('input[name="performance-cookie"]').click((e) => {
            e.stopPropagation();
            if (this.allowPerformanceCookies) {
                this.allowPerformanceCookies = false;
            } else {
                this.allowPerformanceCookies = true;
            }

            $('input[name="performance-cookie"]').prop("checked", this.allowPerformanceCookies);
        });


        $('input[name="behavior-cookie"]').click((e) => {
            e.stopPropagation();
            if (this.allowUserBehaviorCookies) {
                this.allowUserBehaviorCookies = false;
            } else {
                this.allowUserBehaviorCookies = true;
            }

            $('input[name="behavior-cookie"]').prop("checked", this.allowUserBehaviorCookies);
        });

        $(".save-button").on("click", () => {
            $.post("/cookie-settings/modal-edit",
                {
                    allowBehaviorCookies: this.allowUserBehaviorCookies,
                    allowPerformanceCookies: this.allowPerformanceCookies,
                    showMessage: this.showMessage
                },
                (data) => {
                    this.close();
                    window.location.replace(window.location);
                }
            );
        });
    }

    close() {
        this.targetEle.hide();
        window.location.hash = '';
    }
}