import Cookies from '../utilities/Cookies';

export default class SiteMessageBanner{

    constructor(message, className, lifespanMin, isDismissable = true) {
        this.message = message;
        this.lifespan = lifespanMin;
        this.className = className;
        this.isDismissable = isDismissable;
        this.targetEle = $(".SiteMessageBannerContainer");
    }

    render() {
        if (Cookies.get(this.className) === null) {
            let banner = $(`<div class="SiteMessageBanner ${this.className}">
                                <p>${this.message}</p> ${this.isDismissable ? `<button class="dismiss"> X </button>` : ``}
                            </div>`);

            banner.find(".dismiss").on("click", () => {
                this.dismissBanner();
            });

            this.targetEle.append(banner);
            this.targetEle.show();
        }
    }

    dismissBanner() {
        this.targetEle.hide();
        Cookies.set(this.className, 0, this.lifespan);
    }
}

