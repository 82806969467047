import SiteMessageBanner from "./SiteMessageBanner";

export default class TlsDepreciationMessageBanner {
    constructor() {
        let link = `<a target="_blank" rel="noreferrer" href="https://help.overdrive.com/en-us/2271.htm">${window.OverDrive.SiteCopy.get("learnMore")}</a>`;
        let copyToUse = window.OverDrive.SiteCopy.get("tlsEarlyWarningMessage", { 'learnMoreLink': link});
        let banner = new SiteMessageBanner(copyToUse, "TlsDepreciationMessage", 14 * 60 * 24);
        this.render(banner);
    }

    render(banner) {
        let url = "https://www.howsmyssl.com/a/check";
        $.get(url, function (data) {
            let version = data.tls_version.split(' ');
            if (version[0] != 'TLS' || version[1] < 1.2) {
                banner.render();
            }
        });
    }
}
